<template>
    <div v-if="item" class="page-content">
        <page-breadcrumb title="Product Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <b-card class="mt-1">
            <form-generator :model="item" :schema="item_form_schema" />
        </b-card>
        <h4>Product Info</h4>
        <b-card>
            <b-row v-for="(product, index) in item.products" :key="product.product_id + index" class="justify-content-center mb-1">
                <b-col cols="5">
                    <span>Product</span>
                    <multi-select
                        v-if="productOptions"
                        :id="`reward-select-input`"
                        :options="productOptions"
                        :multiple="false"
                        v-model="product.product_id"
                    />
                </b-col>
                <b-col cols="5">
                    <span>Quantity</span>
                    <b-input v-model="product.quantity" type="number" />
                </b-col>
                <b-col class="d-flex align-items-center" cols="1">
                    <b-button
                        variant="danger"
                        size="sm"
                        @click="deleteProduct(index)"
                        >
                        <slot>
                        <feather-icon icon="Trash2Icon" />
                        </slot>
                    </b-button>
                </b-col>
            </b-row>
            <div class="text-center">
                <b-button variant="success" @click="addProduct">Add +</b-button>
            </div>
        </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>  
    
</template>
<script>
const item_form_schema = [
    {
        cols: 4,
        fields: [
            { label: 'Thumbnail', field: 'thumbnail', input_type: 'image' },
        ]
    },
    {
        cols: 8,
        fields: [
            { label: 'Store Id', field: 'store_id', validate: { required: true } },
        ]
    }
];
import service from '../service';
import productService from '../../product/service';
export default {
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
            productOptions: [],
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Iap Product',
                    to: { name: 'iap-product-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created(){
        this.getDetail();
        this.getProduct();
    },
    methods:{
        async getDetail(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            let data = {...this.item};
            await service.update({
                data: JSON.stringify(data),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "iap-product-list" });
        },
        async getProduct() {
            let product = await productService.getAll();
            this.productOptions = product.map((item) => {return {text: item.name, value: item._id}});
        },
        addProduct(){
            this.item.products.push({product_id: null, quantity: null});
        },
        deleteProduct(index){
            this.item.products.splice(index, 1);
        }
    }
}
</script>